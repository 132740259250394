@import "src/shared/styles/custom";

.restaurants-map {
  display: flex;
  .restaurants-list-container {
    z-index: 4;
    width: 25rem;
    padding: 0.313rem 0.938rem 0.313rem 0.625rem;
    margin: 0 auto;

    @media (min-width: 576px) {
      &.restaurants-list-with-map {
        position: absolute;
        top: 0.938rem;
        right: 0.938rem;
        margin: 0;
        padding: 0.313rem;
      }
    }
    .restaurants-list-header {
      position: sticky;
      top: 3.75rem;
      z-index: 2;
      background-color: $body-bg;
      padding: 0.625rem;
      margin: -0.313rem -0.313rem 0;

      .input-search-container {
        margin: 0;
      }
    }
  }

  /* add !important becaus map has position relative, and it's not override without !important*/
  .map-container {
    min-height: 25rem;
    flex: 1;
    position: sticky !important;
    right: 5%;
    top: 3.125rem;
  }

  .restaurant-card {
    display: flex;
    font-size: 0.8rem;
    align-items: flex-start;
    text-align: left;

    &.clicked-plan {
      box-shadow: 0 0 0.438rem 0 $color-zelty;
    }

    .opening-hours-container {
      display: flex;
      flex-direction: column;
    }
    .card-restaurant-order-link {
      width: fit-content;
      color: $color-zelty;
      cursor: pointer;
    }

    .card-restaurant-order-link-disabled {
      cursor: not-allowed;
    }

    .restaurant-container {
      width: 100%;

      .restaurant-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .restaurant-title-text {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          font-size: 1rem;
          width: 100%;
          padding-right: 0.625rem;

          .restaurant-title-badge {
            font-weight: $font-weight-light;
            font-size: $font-size-sm;
            padding-left: 0.625rem;
          }
        }

        .restaurant-title-distance {
          justify-self: flex-end;
          color: $gray-700;
        }
      }
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background-color: $black;
    color: $white;
  }

  .popup-item-restaurant {
    color: $gray-500;
    margin: 0;
  }
}

.restaurant-modal {
  .retrait-commande {
    font-weight: bold;
    font-size: $font-size-sm;
  }
  .item-restaurant {
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    color: $gray-700;
  }
  .restaurant-container {
    width: 100%;
  }
}

/* Mobile display */
@media only screen and (max-width: 600px) {
  .restaurants-map .restaurants-list-container {
    position: unset;
    top: unset;
    right: unset;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 1.25rem;
    .restaurants-list-header {
      position: static;
    }
    .restaurants-list {
      overflow: visible;
    }
  }
  .map-container {
    display: none;
  }

  .restaurant-card {
    width: 100%;
  }
}

.map-container-absolute {
  position: fixed;
  top: 3.75rem;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
