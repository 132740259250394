@import "src/shared/styles/custom";

.back-catalog-link {
  font-size: 0.8rem;
  text-align: left;
  color: var(--bs-body-color);
  text-decoration: none;

  &:hover {
    color: var(--color-zelty);
  }
}

.opt-in {
  display: flex;
  width: 100%;
}

.opt-in-container {
  display: flex;
  flex-direction: column;
}
.checkout-container {
  display: block;
}
.buttons-container {
  padding: 0 1.563rem;

  > button:not(:last-child) {
    margin-bottom: 0.938rem;
  }
}

.checkout-summary-item {
  margin-bottom: 0.625rem;

  svg {
    margin-right: 0.5rem;
  }
}

.icon-payment {
  color: $color-zelty;
}

.order-subtitle {
  font-size: $font-size-sm;
  font-weight: bold;
  padding-top: 0.1rem;
}

.input-font-size-custom {
  font-size: $font-size-xs;
  label {
    color: $gray-600;
  }
}

.input-text-custom {
  min-height: 3.125rem;
  background-color: $white;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border: 0.063rem solid $white;
  padding-left: 2.188rem;
  position: relative;

  &:hover {
    border-color: $color-zelty;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: $white;
    border: 0.063rem solid $gray-400;
    border-radius: 50%;
    margin: 0.813rem 0.5rem;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $white;
    border-color: $color-zelty;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $color-zelty;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  input:checked ~ .checkmark {
    background-color: $white;
    border-color: $color-zelty;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 0.25rem;
    left: 0.25rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background: $color-zelty;
  }
}

.form-check-input:checked {
  background-color: $color-zelty;
  box-shadow: none;
  border-color: $color-zelty;
}

.form-switch {
  cursor: pointer;
  .form-check-input {
    margin-left: 0;
  }
  .form-check-label {
    padding-left: 0.5rem;
    cursor: pointer;
  }

  .form-check-input:not(:active) {
    box-shadow: none;
    border-color: $silver;
    color: $silver;
  }

  .form-check-input:checked {
    border: none;
  }
}

.form-check-input {
  margin-top: 0;
  vertical-align: middle;
}

@include media-breakpoint-up(xs) {
  .item-switch {
    border: 1px solid transparent;
    height: 3.125rem;
    display: flex;
    font-weight: 500;
    align-items: center;
    background-color: $white;
    padding: 1.25rem;
    user-select: none;

    &:hover {
      border-color: $color-zelty;
    }
  }

  .opt-in-item {
    font-size: 0.688rem;
    margin: 0;
    width: 90%;
  }
  .form-custom {
    width: 100%;

    .input-group-form {
      margin-bottom: 0 !important;
    }

    .input-container {
      margin-bottom: 0.938rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .item-switch {
    width: 47%;
  }
}
