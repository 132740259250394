@import "src/shared/styles/custom";
.restaurant-modal {
  .restaurant-title-container {
    display: flex;
    justify-content: space-between;
  }
  .restaurant-input-container {
    position: relative;
    .input-icon {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.375rem;
      color: $gray-600;
    }
    .form-select {
      padding-left: 2.188rem;
    }
  }
}
