@import "src/shared/styles/custom";

.follow-order {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .payment-status-container {
    color: $color-zelty;
  }
  .order-information {
    background-color: $white;

    .quantity-container {
      display: flex;
      justify-content: flex-end;
      margin-right: 0.625rem;
    }
  }

  .order-title {
    font-weight: bold;
  }

  .time {
    font-weight: bold;
  }

  @include media-breakpoint-up(xs) {
    .container-center {
      width: 100%;
    }

    .information-order-item {
      margin: 0;
      color: $gray-700;
      font-size: $font-size-xs;

      svg {
        margin-right: 0.313rem;
        margin-top: -0.25rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .container-center {
      width: 50%;
    }

    .information-order-item {
      margin: 0;
      color: $gray-700;
      font-size: $font-size-sm;
    }
  }
}
