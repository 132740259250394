@import "src/shared/styles/custom";
.top-bar {
  background-color: $white;
  box-shadow: 0 0 0.625rem $gray-400;
  height: $top-bar-height;
  padding: 0;
  .top-bar-logo {
    img {
      height: 2.813rem;
    }
  }

  .nav-main {
    flex: 1;
  }

  .top-bar-left-items,
  .top-bar-right-items {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .order-top-details {
    display: flex;
    align-items: center;
    div {
      margin-right: 0.5rem;
    }
  }

  .top-bar-right-items {
    .dropdown-top-profile-btn {
      width: 2rem;
      height: 2rem;
      &::after {
        display: none;
      }
    }
  }

  .nav-items {
    position: fixed;
    right: 1.25rem;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }
}
