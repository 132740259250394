@import "src/shared/styles/custom";

.item-modal {
  .modal-menu-body {
    .menu-recap-container {
      position: sticky;
      top: 0;
    }
  }
}
