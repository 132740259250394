@import "src/shared/styles/variables";
.PhoneInput {
  box-sizing: border-box;
  position: relative;
  &.phone-input-error {
    input {
      border: 1px solid $color-zelty;

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
      }
    }
  }
  &--focus {
    outline: none !important;
    border: 0;
    box-shadow: none;
  }
  .PhoneInputCountry {
    position: absolute;
    top: 23px;
    left: 10px;
  }
  input {
    padding: 1rem 0.75rem 1rem 45px;
    height: calc(3.5rem + 2px);
    line-height: 1.25;
    border-radius: 0;
    border: 1px solid $gray-400;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      outline: 0;
      border: 0.125rem solid var(--color-zelty);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      box-shadow: none;
    }
    &::placeholder {
      font-size: 0.875rem;
      color: $gray-600;
    }
  }
}
