@import "src/shared/styles/custom";

.restaurant-item-card {
  display: flex;
  cursor: pointer;
  font-size: 0.8rem;
  align-items: flex-start;
  text-align: left;
  flex-direction: row;
  min-height: 6.25rem;
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}

.item-price {
  font-weight: bold;
  color: $color-zelty;
}

.item-infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    font-size: $font-size-xs;
    color: $gray-600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.item-name {
  font-weight: 600;
  font-size: $font-size-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0;
}
.item-quantity {
  color: $color-zelty;
  margin-right: 0.313rem;
}

.item-image {
  flex: 0;
  img {
    width: 6.25rem;
    height: 6.25rem;
  }
}

.image-properties {
  object-fit: cover;
}

.item-price {
  font-weight: bold;
  color: $color-zelty;
}

.product-selected {
  border-left-width: 0.25rem;
  border-left-color: $color-zelty !important;
}

.item-disabled-not-force-dish-modal {
  cursor: not-allowed;
}

.item-disabled-force-dish-modal {
  &.card.card-disabled {
    background-color: $white;
    opacity: 1;
  }
}
