@import "src/shared/styles/custom";
.spinner {
  opacity: 0;
  position: relative;
  width: 2rem;
  height: 2rem;
  &.visible {
    opacity: 1;
  }
  .spinner-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.25rem $color-zelty solid;
    border-radius: 100%;
    border-left-color: transparent;
    animation: spin 0.7s infinite linear;
  }

  &.spinner-sm {
    width: 1.125rem;
    height: 1.125rem;
  }

  &.spinner-lg {
    width: 3rem;
    height: 3rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
