@import "src/shared/styles/custom";

.input-container {
  font-size: $font-size-sm;
  width: 100%;
  label {
    color: $gray-600;
  }
  :focus {
    outline: none !important;
    border: 0.125rem solid $color-zelty;
    box-shadow: none;
  }
}
