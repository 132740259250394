@import "src/shared/styles/custom";

.custom-div-error-payment-methods {
  display: flex;
  align-items: center;
  background-color: $white;
  min-height: 3.125rem;
  padding-left: 0.625rem;
  position: relative;
}
