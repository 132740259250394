@import "src/shared/styles/custom";

.button-no-style {
  all: unset;
  &:hover {
    opacity: 0.9;
  }
}

.icon-quantity-color {
  color: $color-zelty;
}

.disabled {
  cursor: not-allowed;
  color: $gray-600;
}
