@import "src/shared/styles/custom";

.items-list-container {
  max-height: calc(calc(100vh - 6.875rem - 9.375rem - (1.875rem * 2)) - 7.5rem);
  overflow-y: auto;
  ul {
    margin-bottom: 0;
  }
  .basket-empty-basket {
    color: $gray-400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.basket-total-container {
  position: sticky;
  bottom: 0;
  box-shadow: 0 -0.25rem 0.25rem 0 $gray-100;
  background-color: $white;
  padding-bottom: 0.625rem;
}
.item-container {
  .item-info {
    display: flex;

    .item-price {
      text-align: right;
      min-width: 4.375rem;
    }
  }
}
.item-container:not(:last-child) {
  border-bottom: 0.063rem solid $gray-100;
  padding-bottom: 0.625rem !important;
}

.edit-dish-container {
  display: flex;
  justify-content: flex-end;

  .button-edit-dish {
    all: unset;
    color: $color-zelty;
    font-size: 0.8rem;
    text-align: right;
    cursor: pointer;
  }
}

.item-name {
  flex: 3;
}
.quantity {
  width: 1rem;
  text-align: center;
}
.modifier-first-level {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
}

.modifier-second-level {
  font-size: 0.7rem;
}
