@import "src/shared/styles/custom";

.option-value-title {
  display: flex;
  align-items: center;
}

.custom-option-title {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.minimum-choice {
  font-weight: 300;
  font-style: italic;
  font-size: $font-size-sm;
}
