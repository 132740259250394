@import "src/shared/styles/custom";

.input-search-container {
  display: flex;
  flex-direction: row;
  border: 0.063rem solid $gray-500;
  background: $white;
  align-items: center;
  overflow: hidden;
  margin: 0 0.313rem;

  &:hover {
    border-color: $color-zelty;
  }
}
.input-search-container input {
  outline: none;
  border: none;
  background: none;
  font-size: 0.9rem;
  padding: 0.45rem;
  color: inherit;
  flex: auto 1 1;
  width: 100%;
  background-color: transparent;
}
