@import "src/shared/styles/custom";

.collapse-form-comment-text {
  color: $color-zelty;
  font-size: 0.8rem;
  cursor: pointer;
}
.comment-input {
  &:focus {
    outline: 0;
    border: 0.125rem solid var(--color-zelty);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;
  }
}
