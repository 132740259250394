@import "src/shared/styles/custom";
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $body-bg;
  z-index: 9999; /* because botstrap navbar is 1030 */
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  margin-top: 0;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.1s;
  .preloader-logo {
    background-size: cover;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    animation: breath 0.7s infinite;
    opacity: 0.7;
  }
  &.loaded {
    animation: moveAway 0s linear forwards;
    animation-delay: 0.3s;
  }
}

/* Move the div out of bounds after the fadeOut transition */
@keyframes moveAway {
  to {
    margin-top: -100vh;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes breath {
  0% {
    opacity: 0.7;
  }

  60% {
    opacity: 1;
  }
}
