@import "src/shared/styles/custom";
@import "~bootstrap/scss/mixins";

.mode-container {
  height: 100%;

  .return-url {
    position: absolute;
    bottom: 0.625rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: $white;
    border-radius: 0.313rem;
    padding: 0.625rem 0.938rem;
  }

  .mode {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;

    .mode-item {
      width: 100%;
      height: calc(100vh - 60px);
      display: flex;
      align-items: center;
      align-content: center;
      cursor: pointer;
      position: relative;
      font-size: 1.5rem;

      &:not(:last-child) {
        border-right: 0.188rem solid $color-zelty;
      }

      &:hover {
        .mode-item-label {
          color: $gray-700;
        }
        .mode-item-backdrop {
          background-color: $color-zelty;
          opacity: 0.2;
        }
      }
      .mode-item-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .mode-item-label {
        width: 100%;
        text-align: center;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .mode {
      flex-direction: column;

      .mode-item {
        flex: 1 1 auto;
        height: unset;

        &:not(:last-child) {
          border-right-width: 0;
          border-bottom: 0.188rem solid $color-zelty;
        }
      }
    }

    .return-url {
      left: 0.625rem;
      right: 0.625rem;
      transform: unset;
    }
  }
}
