@import "src/shared/styles/custom";

@import "~bootstrap/scss/mixins";

.menu-catalog {
  .restaurant-header {
    background-color: $white;
  }
  .restaurant-nav-container {
    background-color: $white;
    position: sticky;
    top: 3.75rem;
    z-index: 9;
    .restaurant-nav-separator {
      position: absolute;
      width: 100%;
      left: 0;
      border-top: 0.063rem $gray-200 solid;
    }
  }

  .display-catalogue {
    margin-bottom: 10rem;
  }

  @include media-breakpoint-up(xs) {
    .restaurant-catalog-header {
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-up(md) {
    .restaurant-catalog-header {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .item-restaurant {
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    display: flex;
  }

  .container-mobile {
    width: 100%;
    box-shadow: 0 -0.188rem 0.5rem -0.313rem rgba(0, 0, 0, 0.2);
    background-color: $white;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
