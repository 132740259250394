@import "src/shared/styles/custom";
.address {
  .address-input-container {
    display: flex;
    align-items: center;
    position: relative;
    .spinner {
      position: absolute;
      right: 0;
      margin: auto $spacer;
    }
    .address-localize-icon-container {
      position: absolute;
      z-index: 3;
      right: 0;
      margin: auto $spacer;
      color: $gray-600;
      transition-duration: 0.3s;
      opacity: 0.7;
      cursor: default;
      box-shadow: none;
      overflow: hidden;
      &.available {
        cursor: pointer;
        opacity: 1;
        &:hover {
          color: $gray-700;
        }
      }
      &.discrete {
        opacity: 0.1;
      }
    }
  }

  .address-suggestions-container {
    margin-top: 0.75rem;
    .address-suggestion {
      text-align: left;
      cursor: pointer;

      .card {
        padding: 0.5rem;
      }
      &:hover {
        .card {
          background-color: $color-zelty;
          color: $body-bg;
        }
      }
    }
  }
}
