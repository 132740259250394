/* This file contain all custom variables */
/* This is the only file where raw colors are allowed */

/* stylelint-disable color-no-hex */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

:root {
  --color-zelty: #26a69a;
}

$color-zelty: var(--color-zelty);

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #d5d5d5;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #5f5f5f;
$black: #000;
$silver: #bfbfbf;
$color-zelty-border-button-hover: $color-zelty;
$color-zelty-background-button-hover: $color-zelty;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs: $font-size-base * 0.75;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-font-weight: 500;

$btn-border-radius: 0;

$top-bar-height: 3.75rem;

$body-bg: #f7f7f7;

$breadcrumb-active-color: $gray-500;

$breadcrumb-font-size: $font-size-xs;

$card-border-radius: 0;
$modal-sm: 25rem;

$font-weight-semi: 500;

$input-group-addon-bg: $white;

$progress-bar-bg: $color-zelty;

$progress-border-radius: 0;

$progress-height: 0.65rem;

//Stripe
$color-stripe: #32325d;
$color-stripe-invalid: #fa755a;
$color-stripe-autofill: #fefde5;
