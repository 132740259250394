/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

@import "src/shared/styles/custom";

h4 {
  margin: 0;
  margin-top: 1rem;
}

.stripe-card {
  margin: 1rem 0;
}

.error-message {
  margin-top: 1rem;
  color: rgba(255, 0, 0, 0.958);
}

.StripeElement {
  height: 2.5rem;
  padding: 0.625rem 0.75rem;
  width: 100%;
  color: $color-stripe;
  background-color: $white;
  border: 0.063rem solid transparent;
  border-radius: 0.25rem;

  box-shadow: 0 0.063rem 0.188rem 0 $gray-200;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0.063rem 0.188rem 0 $gray-300;
}

.StripeElement--invalid {
  border-color: $color-stripe-invalid;
}

.StripeElement--webkit-autofill {
  background-color: $color-stripe-autofill !important;
}
