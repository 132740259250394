.dish-presentation-container {
  margin: -1rem;
}

.dish-presentation-container,
.menu-presentation-container {
  overflow: hidden;

  .close-button {
    display: flex;
    z-index: 2;
    position: absolute;
    right: 1rem;
    top: 1rem;

    align-items: center;
    justify-content: center;

    button.btn-close {
      color: var(--color-zelty);
      width: 35px;
      height: 35px;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 200px;
    }
  }

  .z-image {
    width: 100%;

    & > img {
      max-height: 12.5rem;
      width: 100%;
    }
  }

  .dish-presentation {
    padding: 1rem;
  }

  .menu-presentation {
    padding: 1rem 0 0;
  }
}
