@import "src/shared/styles/custom";

.minimum-choice {
  font-weight: 300;
  font-style: italic;
  font-size: $font-size-sm;
}

.custom-option-title {
  font-weight: 700;
  margin: 0;
}
