@import "variables";
@import "~leaflet/dist/leaflet.css";
@font-face {
  font-family: Poppins;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* must match the value in App.tsx -> <CSSTransition  [timeout] /> */
$transition-page-duration: 0.5s;
/**/
.app {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.content {
  flex: 1;
  margin-top: $top-bar-height;
  position: relative;
  > .spinner {
    margin: 10rem auto 0;
  }
  /* navigation animations */
  .page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &.page-enter-active {
      animation: pageEnter $transition-page-duration ease forwards;
    }
    &.page-exit-active {
      animation: pageExit $transition-page-duration ease forwards;
    }
  }
}

@keyframes pageEnter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes pageExit {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

/**
Helper classes
*/
.centered-component {
  .row {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

// Used to wrapp preloader components
.preloader-component {
  height: 100%;
  overflow: hidden;
}

/**
Btn reskin
*/

.btn {
  border-radius: 0px;
  height: 3.125rem;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.btn-circle {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    border: 0.125rem $color-zelty solid;
    color: $color-zelty;
    font-weight: bolder;
    transition-duration: 0.1s;
    user-select: none;
    &:hover {
      color: $body-bg;
      background-color: $color-zelty;
    }
    &.btn-circle-20 {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.btn-block {
  width: 100%;
  height: auto;
}

/**
 Default button reskin
*/
.btn-default,
.btn-active {
  align-items: center;
  justify-content: center;
  border: 0.063rem $gray-400 solid;
  background-color: $white;

  transition-duration: 0.1s;

  svg {
    margin-right: 0.5rem;
  }

  &:focus {
    border-color: $color-zelty;
  }
}

.btn-active {
  border-color: $color-zelty;
  border-width: 0.125rem;
  color: $color-zelty;
  svg {
    color: $color-zelty;
  }
  &:hover {
    color: $color-zelty;
  }
}

.btn-primary {
  background-color: $color-zelty;
  color: $white;
  border: none;
  &:hover,
  &:disabled,
  &:focus,
  &:active {
    opacity: 0.9;
    background-color: $color-zelty-background-button-hover;
    border-color: $color-zelty-border-button-hover;
  }
}

/**
CARD reskin
*/

.card {
  border-width: 0;
  &:last-child {
    margin-bottom: 0.625rem;
  }
  &.shadowed {
    box-shadow: 0 0.313rem 0.313rem rgb(235, 235, 235);
  }

  &.bordered {
    border-left-width: 0.25rem;
    &.card-primary:hover {
      border-left-color: $color-zelty;
    }
  }
  &.card-disabled {
    background-color: $gray-200;
    border: 0.063rem $gray-300 solid;
    opacity: 0.4;
    &.card-primary:hover {
      border-left-color: $gray-300;
    }
  }
  &:not(:hover) {
    border-left-color: $gray-300;
  }
}

/*
Form reskin
*/
.form-control {
  border-radius: 0;
  border-color: $gray-400;
}

/* link */
a {
  text-decoration: none;
  font-weight: bold;
  color: $gray-700;
  &:hover {
    color: $color-zelty;
  }
}

/*
 nav 
*/
.nav {
  .nav-item {
    .nav-link {
      color: $black;
      font-weight: bold;
      font-size: $font-size-xs;
      &.active {
        background-color: $color-zelty;
        color: $gray-100;
      }
    }
  }
}

/* Modal */

.modal {
  .modal-body {
    background: $gray-100;
  }
}

h4 {
  @include media-breakpoint-up(xs) {
    font-weight: 600;
    font-size: 1rem;
  }

  @include media-breakpoint-up(md) {
    font-weight: bold;
    font-size: 1.375rem;
  }
}

h5 {
  @include media-breakpoint-up(xs) {
    font-weight: 500;
    font-size: 0.75rem;
  }

  @include media-breakpoint-up(md) {
    font-weight: 500;
    font-size: 1rem;
  }
}
/*enable negative margin bootstrap*/
$enable-negative-margins: true;

.custom-link {
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.875rem;
  color: $black;
}

.badge-custom {
  background-color: $white;
  border: 0.063rem solid $color-zelty;
  color: $color-zelty;
}

/** 
Small font size
**/

small {
  color: $gray-600;
}

/**                  */
/** Custom scrol bar */
/**                  */

* {
  /* width */

  > ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  /* Track */
  > ::-webkit-scrollbar-track {
    background: transparent;
    transition-duration: 1s;
  }

  /* Handle */
  > ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0.188rem;
    transition-duration: 1s;
  }

  /* Handle on hover */
  > ::-webkit-scrollbar-thumb:hover {
    background: transparent;
    transition-duration: 1s;
  }

  &:hover {
    /* Handle */
    > ::-webkit-scrollbar-thumb {
      background: $gray-500;
    }

    /* Handle on hover */
    > ::-webkit-scrollbar-thumb:hover {
      background: $color-zelty;
    }
  }

  .custom-checkbox {
    display: flex;
    align-items: center;

    input:not(:active) {
      box-shadow: none;
      border-color: $silver;
    }

    input:checked {
      border: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .restaurant-header {
    img {
      max-height: unset;
    }
  }
}
