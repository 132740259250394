@import "src/shared/styles/custom";

.input-dish-option-custom {
  min-height: 50px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $white;
  padding-left: 35px;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: $color-zelty;
  }

  label {
    cursor: pointer;
  }

  &.option-checked {
    border: 0.063rem solid $color-zelty;
    input {
      border-color: $color-zelty;
    }

    label {
      color: $color-zelty;
    }
  }

  .form-check-input:checked {
    background-color: $color-zelty;
  }

  &.disabled-input {
    pointer-events: none;
  }
  .dish-price-supplement {
    color: var(--color-zelty);
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
}
