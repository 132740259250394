/* stylelint-disable color-no-hex */
/* we can disable some rules here since it's for dev only */

.debug {
  position: fixed;
  z-index: 9000;
  bottom: 0;
  right: 0;
  background-color: #212121;
  color: #ccc;
  border: 0.063rem #000 solid;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0.313rem;
  font-size: 0.65rem;
  display: flex;
  flex-direction: column;
  max-height: 15.625rem;
  max-width: 100%;
  overflow: auto;
  a {
    color: #ccc;
    text-decoration: underline;
    margin: auto 0.313rem;
  }
  h1 {
    font-size: 0.8rem;
  }

  .debug-cat {
    h2 {
      color: #fff;
      font-size: 0.7rem;
      margin: 0;
    }
  }
}
