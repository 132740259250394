@import "src/shared/styles/custom";

.color-zelty {
  color: $color-zelty;
}

.horizontal-separator {
  width: 100%;
  margin: 0;
  padding: 0.063rem;
}

.custom-sticky-position {
  top: 9rem;
}

.checkout-basket {
  margin-right: 0;
  max-width: 21.875rem;
}

.modify-link {
  color: $color-zelty;
  text-decoration: underline;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.basket-icon-information {
  margin-right: 0.313rem;
}
.basket-title-container {
  display: flex;
  justify-content: center;
  .basket-icon {
    margin-top: -0.188rem;
    width: 1.875rem;
    padding-right: 0.313rem;
  }
  .basket-count-items {
    width: 1.875rem;
  }
}

@media only screen and (min-width: 1340px) {
  .checkout-basket {
    max-width: 28.125rem;
  }
}

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
  .checkout-basket {
    position: fixed;
    bottom: 0;
    top: unset;
    left: 0;
    right: 0;
    max-width: 100%;
    margin-right: auto;
  }
  .form-custom {
    margin-bottom: 16.4rem;
  }
}
