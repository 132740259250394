@import "src/shared/styles/custom";

.item-title {
  font-size: $font-size-sm;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0;
}

.color-zelty {
  color: $color-zelty;
}
