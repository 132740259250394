@import "src/shared/styles/custom";

.custom-label-optin {
  font-size: 0.8rem;
  cursor: pointer;
}

.form-check-input {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  box-shadow: none;
}

.optin-message {
  padding-top: 0.075rem;
}
