.back-button {
  border-radius: 0.313rem !important;
  padding: 0 2rem;
}
.error-icon {
  color: rgb(207, 207, 207);
}

.error-text {
  white-space: pre-wrap;
}

.error-container {
  max-width: 40vw;
  text-align: center;
}
