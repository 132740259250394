@import "src/shared/styles/custom";

.color-zelty {
  color: $color-zelty;
}

.modifier {
  font-size: 0.8rem;
}

.title-menu-part,
.text-button {
  font-size: 0.9rem;
}
