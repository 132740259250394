@import "src/shared/styles/custom";
.z-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: fit-content;
  block-size: fit-content;

  padding: 0;
  .spinner {
    position: absolute;
  }
  img {
    margin: 0 !important;
    opacity: 0;
    object-fit: cover;
    transition-duration: 0.3s;
    will-change: opacity;

    height: 100%;
    max-height: 12.5rem;
  }

  // Only display animation if not loading
  &:not(.loaded) {
    background-color: $gray-200;
    animation: fadeLoad 1s infinite ease-in-out;
  }

  &.loaded {
    .spinner {
      opacity: 0;
    }
    img {
      opacity: 1;
    }
  }

  &.error {
    .z-image-original {
      display: none;
    }
  }
}

@keyframes fadeLoad {
  50% {
    background-color: darken($gray-200, 2%);
  }
}
